<template>
  <div class="authWrapper">
    <div class="container">
      <div class="row whiteBG-wrap">
        <div class="col-lg-6 col-md-6 no-padding">
          <div class="left-bg-wrapper text-center">
            <h1>Forgot your Password?</h1>
            <p>
              Don't Worry! we've got your back. Just follow the instructions and
              you'll be good :)
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="auth-form-wrap form-reset">
            <div class="auth-form">
              <h2>Reset Password</h2>
              <!--<form>
                                <div class="form-group mb-4">
                                    <label>New Password</label>
                                    <input type="password" placeholder="Enter your New Password" class="form-control auth-form-input">
                                </div>
                                <div class="form-group mb-4">
                                    <label>Confirm Password</label>
                                    <input type="password" placeholder="Enter your New Password Again" class="form-control auth-form-input">
                                </div>
                                <div class="form-group mb-4">
                                    <button type="submit" class="submit-btn">Continue</button>
                                </div>
                            </form> -->
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.prevent="handleSubmit(changePassword)">
                  <ValidationProvider
                    rules="required"
                    name="New Password"
                    vid="newpassword"
                  >
                    <b-form-group
                      label="New Password"
                      label-for="input-1"
                      slot-scope="{ valid, errors }"
                    >
                      <div class="changeForm-group errorIcon_eye">
                        <b-form-input
                          id="input-1"
                          type="text"
                          v-model="newpassword"
                          :state="errors[0] ? false : valid ? true : null"
                          placeholder="Enter your new password"
                        ></b-form-input>
                        <eye-button
                          id="input-1"
                          class="toggle-eye-icon"
                          hideOnDefault="true"
                          type="text"
                        ></eye-button>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </b-form-group>
                  </ValidationProvider>

                  <b-form-group label=" " label-for="">
                    <div>
                      <password-strength-bar
                        @updateScore="setPasswordScore"
                        :password="newpassword"
                      ></password-strength-bar>
                    </div>
                  </b-form-group>

                  <ValidationProvider
                    rules="required|confirmed:newpassword"
                    name="Confirm Password"
                  >
                    <b-form-group
                      label="Confirm Password"
                      label-for="input-2"
                      slot-scope="{ valid, errors }"
                    >
                      <div
                        class="changeForm-group"
                        :class="errors[0] || valid ? 'errorIcon_eye' : ''"
                      >
                        <b-form-input
                          id="input-2"
                          type="text"
                          :state="errors[0] ? false : valid ? true : null"
                          v-model="confirmpassword"
                          placeholder="Repeat new password"
                        ></b-form-input>
                        <eye-button
                          id="input-2"
                          class="toggle-eye-icon"
                          hideOnDefault="true"
                          type="text"
                        ></eye-button>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </b-form-group>
                  </ValidationProvider>

                   <div class="error_message" v-if="formErrorFlag == true">
                {{ formErrors["error"] }}
              </div> 
                  <button type="submit" class="submit-btn" :disabled="verifyLoader">
                    <b-spinner v-if="verifyLoader" small></b-spinner>
                    {{
                      verifyLoader ? "Changing Password..." : "Change Password"
                    }}
                  </button>
                </b-form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios"
import helper from "@/resource/Helper"

export default {
  name: "ResetPassword",
  data() {
    return {
      loader: false,
      newpassword: "",
      confirmpassword: "",
      formErrorFlag: false,
      formErrors: [],
      verifyLoader: false,
      passwordScore: 0,
    };
  },
  methods: {
    changePassword() {

         if (this.passwordScore < 60) {
        this.formErrorFlag = true;
        this.formErrors["error"] = "Please use strong password.";
        return;
      }
        this.verifyLoader = true;
        let url = this.$endpoint.API_FORGOT_PASSWORD;
        let app = this
          axios
        .post(url, {
          password: this.newpassword,
          reset_code: this.otp,
          option: "email",
          data: this.email,
        })
        .then(function(response) {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            helper.delayRedirect("Login", 0);
          }
        })
        .catch(function(error) {
           helper.infoMessage(
              app,
              error.data.status,
              error.data.message
            );
         
        }).finally(()=> {
             this.verifyLoader = false;
        });
    },
    setPasswordScore(score) {
      console.log(score);
      this.passwordScore = score;
    },
  },
  computed: {
    ...mapGetters({
      deviceId: "deviceId",
      email: "repEmail",
      otp: "OTPCode",
    }),
  },
  created() {
    if (!this.email || !this.otp) {
      this.$router.push({ name: "ForgotPassword" });
    }
  },
};
</script>
<style src="@/assets/css/authen.css" scoped></style>

<style>
#label-username,
#label-password, .form-group label {
  font-size: 16px !important;
  color: #909090 !important;
  margin-bottom: 10px !important;
}
#username,
#password,.form-group input {
  font-size: 14px !important;
  padding: 12px 15px !important;
}
.submit-btn {
    margin-top: 5%
}
.error_message {
  color: red !important;
}

</style>
